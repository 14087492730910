.search2 {
  height: 50px;
  z-index: 99;
}
.searchInputs {
  display: flex;

  margin-left: 10px;
  margin-top: 5px;
  background-color: white;
  display: flex;
  align-items: center;

  width: 400px;
}
.searchInputs i {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
}
.search2 input {
  background-color: white;
  border: 0;
  border-radius: 10px;

  font-size: 14px;
  padding: 20px;
  height: 35px;
  width: 100%;
}

.searchIcon {
  height: 60px;
  width: 50px;
  background-color: white;
  display: grid;
  place-items: center;
}

input:focus {
  outline: none;
}
.searchIcon svg {
  font-size: 35px;
}

.dataResult {
  margin-top: 0px;
  margin-left: 10px;
  padding-left: 20px;
  width: 370px;
  height: 200px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
}

.dataResult::-webkit-scrollbar {
  display: none;
}

.dataResult .dataItem {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  color: black;
}
.dataItem {
  display: flex;
  justify-content: space-between;
  padding: 0 5px 0 0;
}
.dataItem p {
  margin-left: 10px;
}
a {
  text-decoration: none;
}

.dataItem:hover {
  background-color: lightgrey;
}

#clearBtn,
.dataItem {
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .searchInputs {
    width: 300px;
  }
}
