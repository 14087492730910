@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600&family=Roboto:wght@100;400;700;900&display=swap");
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}
html {
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  display: none;
}

:root {
  /* --color-bg: #1f1f38; */
  --color-bg: #080f0fd7;
  /* --color-bg-variant: #2c2c6c; */
  --color-bg-variant: #36486b;
  --color-primary1: #256eff;
  --color-primary: #4db5ff;
  --color-primary-variant: rgba(77, 181, 255, 0.4);
  --color-white: white;
  --color-light: rgba(255, 255, 255, 0.6);
  --transition: all 400ms ease;
  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-ms: 90%;
  /*Social media*/
  --color-primary-social: #e28743;
  --color-secondary-social: #154c79;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-family: "Roboto", sans-serif;

  color: white;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.homepage {
  background-color: black;
  /* background-color: white; */

  overflow-y: scroll;
}
.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}
h1,
h2,
h3,
h3,
h4,
h5 {
  font-weight: 500;
}
section {
  margin-top: 8rem;
}
section > h2,
section > h5 {
  text-align: center;
  color: var(--color-light);
}
section > h2 {
  color: var(--color-primary);
  margin-bottom: 3rem;
}
.text-light {
  color: var(--color-light);
}
a {
  color: var(--color-primary);
  transition: var(--transition);
}
a:hover {
  color: var(--color-white);
}
.btn {
  width: max-content;
  display: inline-block;
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}

.btn:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}
.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg);
}

.btn-secondary {
  background-color: var(--color-secondary-social);
  color: white;
}

.btn-alternate {
  border: none;
  width: 100%;
  max-width: 15rem;
  letter-spacing: 2.5px;
  padding: 15px 5px;
  font-weight: 700;
  border-radius: 4px;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
}

.btn-secondary:hover {
  /* background-color: var(--color-secondary-social); */
  color: white;
}
img {
  display: block;
  width: 100%;
  object-fit: cover;
}
.homepage-overlay {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: -1;
}
.homepage-overlay img {
  width: 100%;
  height: 100%;
}
.btn-underline {
}
/*--Media Queries for medium devices */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }
  section {
    margin-top: 6rem;
  }
}
/*--Media Queries for medium devices */
@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-ms);
  }
  section {
    margin-top: 2rem;
  }
}

@media screen and (min-width: 1024px) {
  .btn-alternate:hover {
    background-color: var(white);
    box-shadow: 0px 15px 20px rgba(46, 104, 229, 0.4);
    color: var(--color-primary);
    transform: translateY(-7px);
  }
  #contact-btn :hover {
    /* background-color: var(--color-alternate); */
    box-shadow: 0px 15px 20px goldenrod;
    color: #fff;
    transform: translateY(-7px);
  }
}
