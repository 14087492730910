.nav-right {
  position: fixed;

  top: 80px;

  left: calc(50vw + 15vw + 20px);

  color: black;
}
.nav-right-stats {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 400px;

  border: 1px solid grey;
  text-align: center;
  grid-row-gap: 0px;
  background: white;

  padding: 7px;
  cursor: pointer;
  transition: 400ms ease-in-out;
}
.nav-right-stats-weekly {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.nav-right-stats-weekly-info {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 5px;
}
.nav-right-img {
  width: 95px;
  height: 95px;
  border-radius: 50%;
  border: 2px solid var(--color-primary-social);
}
.nav-right-stats-weekly-info h4 {
  color: var(--color-secondary-social);
}
.nav-right-like-count {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.secondary-like-count {
  justify-content: space-between;
  padding: 0 5px;
}
.secondary-like-count i {
  color: var(--color-primary-social);
}
.stat-count {
  width: 50%;

  display: flex;
  flex-direction: column;
  gap: 5px;
}
.btn-social-primary {
  font-size: 16px;
  color: white;
  background: var(--color-primary-social);
  cursor: pointer;
}
.btn-social-primary:hover {
  border: 1px solid var(--color-primary-social);
  background: var(--color-secondary-social);
  color: white;
}
.border {
  margin-top: 7px;
  margin-left: 4px;
  background-color: var(--color-secondary-social);
  width: 15%;
  height: 12%;
}
.alternate {
  background-color: var(--color-primary-social);
}
.nav-right-stats-header {
  text-align: left;
  padding-left: 5px;
  text-transform: uppercase;
}
.nav-right-ads {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  color: white;
  margin-top: 2vh;
  height: 350px;

  border: 2px solid grey;
  text-align: center;
  grid-row-gap: 0px;

  background: url(./ad.jpg) no-repeat center center/cover;
  cursor: pointer;
  transition: 400ms ease-in-out;
}
.nav-right-stats:hover {
  transform: scale(1.02);
}
.nav-right-ads:hover {
  transform: scale(1.02);
}
.show {
  background-color: var(--color-secondary-social);
  color: white;
}

@media screen and (max-width: 1424px) {
}

@media screen and (max-width: 1224px) {
  .nav-right {
    display: none;
  }
}
