@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&display=swap");

.friendsSearch {
  background: rgba(236, 228, 228, 0.763);
  padding: 0 2px;
}
.content.friendsSearch {
  top: 80px;
  width: 30vw;
  left: 50%;
  padding-top: 0;
  height: fit-content;
  border: 1px solid rgba(128, 128, 128, 0.562);
}
.fr-search-main {
  padding-top: 10px;
}
.fr-search-main h2,
h3 {
  font-weight: 700;
  color: var(--color-secondary-social);
  margin: 5px 0;
  padding: 10px;
}
.fr-search-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.fr-search-main img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 1px solid rgba(128, 128, 128, 0.327);
}

.fr-request {
  padding: 0 5px;

  min-height: 310px;
  margin: 5px 0;
}
.fr-request-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
.fr-request-profile {
  border: 1px solid rgba(128, 128, 128, 0.5);
  display: flex;
  background-color: whitesmoke;
  height: 250px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.fr-request-profile h5 {
  font-weight: 700;
  color: rgba(0, 0, 0, 0.789);
}
.fr-action {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.fr-action button {
  width: 100%;
  width: 7rem;
  gap: 5px;
  font-size: 13px;
  padding: 7px;
  cursor: pointer;
}

@media screen and (max-width: 1224px) {
  .content.friendsSearch {
    transform: translateX(0);
    width: 58vw;
    left: 35vw;
  }
  .fr-request-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 768px) {
  .content.friendsSearch {
    width: calc(100vw - 6px);
    top: 100px;
    left: 3px;
  }
  .fr-request-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
