header {
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
}

.header_container {
  text-align: center;
  height: 100%;
  position: relative;
}
.header_container h1 {
  font-size: 50px;
  font-weight: 700;
  letter-spacing: 2px;
}
/******CTA****/
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
  font-size: 20px;
  letter-spacing: 1.5px;
}

/******Socials****/
.header_socials {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 35vh;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 3rem;
}
.header_socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background-color: var(--color-primary);
}
.header_socials::before {
  content: "";
  width: 1px;
  height: 2rem;
  background-color: var(--color-primary);
}
/***********************ME********************/
.me {
  background: linear-gradient(white, transparent);
  width: 22rem;
  height: 30rem;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 18rem;
  border-radius: 12rem 12rem 0 0;
  padding: 5rem 1.5rem 1.5rem 1.5rem;
}
/*scroll-down*/
.scroll_down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 200;
  font-size: 0.9rem;
}
.line-1 {
  position: relative;

  margin: 0 auto;

  text-align: center;
  white-space: nowrap;
  overflow: hidden;
}
.anim-typewriter {
  animation: typewriter 4s steps(44) 1s 1 normal both;
  /* blinkTextCursor 500ms steps(44) infinite normal; */
}
@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 24rem;
  }
}

/*queries*/
@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }
}

@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }

  .header_socials,
  .scroll_down {
    display: none;
  }
  .cta {
    font-size: 16px;
  }
}
