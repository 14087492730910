#portfolio {
}
.portfolio_container {
  display: grid;
  margin: 5rem auto;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.25rem;
}
.container h2 {
  margin-top: 20px;
  font-size: 34px;
  font-weight: 700;
  letter-spacing: 3.5px;
}
.portfolio_item {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 1rem;
  border: 1px solid transparent;
  transition: var(--transition);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  min-height: 550px;
}
.portfolio_item:nth-child(1) {
  background-color: #e28843ad;
}
.portfolio_item:nth-child(2) {
  background-color: white;
  color: #946bde;
}
.portfolio_item:nth-child(2) h3 {
  color: #946bde;
  border-bottom: 1px solid #946bde;
}

.portfolio_item:nth-child(2) a:nth-child(even) {
  background-color: transparent;
  color: #946bde;
}
.portfolio_item:nth-child(2) a:nth-child(even):hover {
  background-color: white;
}
.portfolio_item:nth-child(3) {
  background-color: #9a031ead;
}
.portfolio_item:nth-child(2) {
  background-color: #5448c8ad;
  color: white;
}
.portfolio_item:nth-child(2) h3 {
  color: white;
}

.portfolio_item:nth-child(5) {
  background-color: #e98a15ad;
}
.portfolio_item:nth-child(4) {
  background-color: #7f96ff;
}
.portfolio_info {
  display: flex;
  flex-direction: column;

  line-height: 1.6em;
  letter-spacing: 1.5px;
  padding: 2px;
  height: 175px;
}
.portfolio_info h3 {
  text-transform: uppercase;
  border-bottom: 2px solid rgba(255, 255, 255, 0.63);
}
.portfolio_info p {
  font-size: calc(12px + 0.25vw);
  margin-bottom: 5px;
}
.portfolio_image {
  padding: 20px;

  /* background-color: steelblue; */
}
.portfolio_image img {
  width: auto;
}
.portfolio_links {
  display: flex;
  margin: 10px auto;
  gap: 1rem;
}

.portfolio_item h3 {
  color: white;
}
.spacing {
  display: flex;
  gap: 5px;
}
#e28743 {
  background-color: #e28743;
}
/* .social-media-project {
  background: var(--color-primary-social);
  color: black;
} */
@media screen and (max-width: 1224px) {
  .portfolio_container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
  .portfolio_image {
    min-height: 150px;
  }
}
@media screen and (max-width: 750px) {
  .portfolio_container {
    grid-template-columns: 1fr;
  }
  .portfolio_item {
    padding: 1.2rem 0.8rem;
    margin: 0 auto;
    max-width: 500px;
  }
  .portfolio_image {
    min-height: 100px;
  }
}
@media screen and (min-width: 751px) {
  .portfolio_item:hover {
    border-color: var(--color-primary-variant) !important;
    background: transparent;
  }
}
