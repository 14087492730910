@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600&family=Roboto:wght@100;400;700;900&display=swap");
.nav-top-container {
}

.social-nav-top {
  position: fixed;
  top: 0;
  height: 60px;

  width: 100%;
  z-index: 5;
  background: var(--color-primary-social);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 20px;

  font-family: "Roboto", sans-serif;
  transition: all 300ms smooth;
  border-bottom: 1px solid rgba(128, 128, 128, 0.385);
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 25rem;
}
.logo h3 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.sm-icon {
  display: none;
}
.search {
  display: grid;
  grid-template-columns: 5fr 1fr;
  align-items: center;
  padding: 0px 5px;
  color: grey;

  border-radius: 10px;
}
.search-bar {
  background-color: rgba(255, 255, 255, 0.651);
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-primary-social);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
/* .logo input {
  height: 2.5rem;
  width: 100%;
  min-width: 12rem;
  max-width: 20rem;
  padding: 0 1rem;
  border: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
} */
.top-nav-img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.nav-top-sb1 {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}
.fa.fa-search i {
  color: var(--color-secondary-social) !important;
}
.social-nav-top ul {
  width: 40%;
  max-width: 600px;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.menu-bar {
  display: none;
}
.social-nav-top ul i {
  font-size: 24px;
}
.social-nav-top li {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.social-nav-top ul li:hover {
  color: var(--color-secondary-social);
}

a:visited,
a {
  color: white;
}
.new-searchBar {
  background-color: aliceblue;
  height: 500px;
  border: 3px solid red;
  width: 400px;
}
.dropDown {
  position: absolute;
  right: 20px;
  top: 55px;
  background: whitesmoke;
  border: 2px solid rgba(128, 128, 128, 0.5);
  border-radius: 5px;
  min-height: 300px;
  max-height: 900px;
  overflow-y: scroll;
  width: 325px;
}
.dropDown-Notifications {
  color: black;
  width: 300px;

  overflow-x: hidden;
}
.dropDown-Notifications h2 {
  font-weight: 700;
  color: var(--color-secondary-social);
  padding: 10px;
}

.notification-select {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  padding: 10px;
}
.notification-select > div {
  color: white;
  background-color: var(--color-secondary-social);
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}
.notice-select {
  color: var(--color-secondary-social) !important;
  background: whitesmoke !important;
  border: 1px solid var(--color-secondary-social);
}
.notification {
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 3fr 0.5fr;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
}
.notification:hover {
  background: rgba(128, 128, 128, 0.132);
}
.notification img,
.nav-top-sb1 img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
.notification-details {
  display: grid;
  gap: 5px;
  width: 100%;
}
.notifications-action {
  cursor: pointer;
  display: flex;
  gap: 5px;
  margin: 5px auto;
}
.notifications-response {
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: center;
}
.notifications-action button {
  height: 40px;
  cursor: pointer;
}
.notice-msg-box {
  border: 1px solid rgba(128, 128, 128, 0.521);
}
.notice-msg-box img {
  height: 35px;
  width: 35px;
  border-radius: 50%;
}
.messages-msg {
  display: grid;
  grid-template-columns: 1fr 4fr;
  align-items: center;
  gap: 20px;
  padding: 5px;
}
.message-reply {
  display: grid;
  grid-template-columns: 3fr 1fr;
  padding: 0 2px;
}
.message-reply input {
  background-color: rgba(128, 128, 128, 0.185);
  border: 1px solid rgba(128, 128, 128, 0.358);
  padding: 10px 5px;
  height: 90%;
  border-radius: 5px;
}
.message-reply button {
  height: 90%;
  margin: 1px auto;
  cursor: pointer;
}
.notifications-icon {
  position: relative;
}
.notification-count {
  position: absolute;
  color: white;
  font-size: 12px;
  font-weight: 700;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  padding: 5px;
  left: 50%;
  top: 0;
  background-color: crimson;
}
@media screen and (max-width: 1224px) {
  .social-nav-top h5 {
    display: none;
  }
  .social-nav-top {
  }
  .logo {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .social-nav-top {
    padding-top: 5px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0px;
    display: grid;
    grid-template-columns: 1fr;
    height: auto;
    align-items: center;
  }

  .fab.fa-pied-piper-alt.fa-2x.sm-icon {
    display: none;
  }
  .social-nav-top ul {
    background-color: whitesmoke;
    margin-top: 5px;
    padding: 5px;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    color: var(--color-primary-social);
  }
  .social-nav-top i {
    color: var(--color-primary-social);
  }
  .dropDown {
    top: 90px;
    right: 10px;
    width: 300px;
  }
  .menu-bar {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logo h3 {
    display: none;
  }
  .sm-icon {
    display: block;
  }
}
.trending:hover {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  /* animation: shake 1s;

  animation-iteration-count: infinite; */
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-0.5deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(0.5deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(0.5deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-0.5deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-0.5deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(0.5deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-0.5deg);
  }
}
