.nav-left {
  background-color: transparent;
  position: fixed;
  top: 80px;
  width: 250px;
  right: calc(50vw + 15vw + 20px);
  color: black;
}

.nav-left-profile {
  display: grid;
  grid-template-rows: 6fr 2fr 2fr 1fr;
  height: 375px;

  border: 1px solid rgba(128, 128, 128, 0.489);
  text-align: center;
  grid-row-gap: 0px;
  background: white;
}
.nav-left-profile > div {
  border-bottom: 1px solid rgba(128, 128, 128, 0.795);
}
.nav-left-profile-image {
  position: relative;
}
.nav-left-img {
  position: absolute;

  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 5px;
  width: 100%;
}

.nav-left-img img {
  width: 85px;
  height: 85px;
  border-radius: 50%;
}

.nav-left-profile-image-color1 {
  position: absolute;
  width: 100%;
  height: 40%;
  background: var(--color-primary-social);
}
.nav-left-profile-image-colord {
  display: none;
  position: absolute;
  width: 100%;
  height: 4%;
  background: var(--color-primary-social);
}
.nav-left-follow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.nav-left-view {
  background-color: var(--color-primary-social);
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-left-suggestions {
  background-color: white;

  height: auto;
  margin-top: 2vh;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(128, 128, 128, 0.438);
}
.nav-left-header {
  background-color: var(--color-primary-social);
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid white;
  margin: 0 auto 10px;
  padding: 10px;
  color: white;
  line-height: 1.6rem;
}
.nav-left-suggested-profile {
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  align-content: center;
  padding: 5px;
  align-items: center;
  grid-column-gap: 12px;
  color: black;
}
.nav-left-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  text-align: left;
  gap: 5px;
  letter-spacing: 0.1em;
  cursor: pointer;
}
.nav-left-info h5 {
  color: grey;
}

.nav-left-suggested-profile img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}
.nav-left-add {
  display: flex;

  justify-content: center;
  align-items: center;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.322);
}
.nav-left-add:hover {
  color: white;
  background-color: var(--color-primary-social);
  border: 1px solid grey;
}
.nav-left-suggested-profile i {
  cursor: pointer;
}
.nav-left-dummy {
  display: none;
}
.md-suggestion-icons i {
  display: none;
}

/*Suggestions animation*/

@media screen and (max-width: 1224px) {
  .nav-left-view {
    display: none !important;
  }
  .nav-left-suggested-profile,
  .nav-left-header {
    display: none;
  }
  .md-suggestion-icons i {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15vh;
    color: var(--color-primary-social);
  }
  .nav-left-dummy {
    display: block;
    height: 50px;
    width: 50px;
    background-color: blue;
  }
  .nav-left-profile-image-colord {
    display: block;
  }
}
