#contact {
  width: 75%;
  height: auto;
  border: 1px solid grey;
  margin: 10px auto;
  text-align: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 150px;
  padding: 40px 15px;
}
#contact h1 {
  font-size: 28px;
  margin: 10px auto;
  font-weight: 700;
  border-bottom: 2px solid rgba(255, 255, 255, 0.788);
}
#contact form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 300px;

  text-align: center;
}
#contact label {
  color: white;

  border: none;
  display: flex;
  justify-content: center;
  text-align: left;
}
#contact input {
  width: 70%;
  min-width: 17rem;
  height: 3.5rem;
  padding: 10px 7px;
  border-radius: 5px;
  font-size: 18px;
  text-align: center;
}
#contact textarea {
  width: 100%;
  min-width: 17rem;
  height: 13rem;
  margin-bottom: 10px;
  padding: 10px 7px;
  font-size: 18px;
  border-radius: 5px;
}
#contact button {
  width: 80%;
  height: 3rem;
  font-size: 20px;
  border: none;
  cursor: pointer;
}
#contact label:hover {
  border: none;
  background-color: transparent;
}
@media screen and (max-width: 1024px) {
  #contact {
    width: 87%;
  }
  #contact p {
    font-size: 18px;
  }
}
