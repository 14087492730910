body {
  color: white;
}
.nav {
  position: relative;
}

.content {
  position: absolute;
  width: 30vw;
  min-width: 300px;
  height: 100vh;
  padding-top: 80px;
  top: 0vh;

  transform: translateX(-50%);
  left: 50%;
  color: black;
}

@media screen and (max-width: 1224px) {
  .nav-left {
    right: auto;
    left: calc(17.5vw - 125px);
  }
  .content {
    transform: translateX(0);
    width: 58vw;
    left: 35vw;
  }
}

@media screen and (max-width: 768px) {
  .nav-left,
  .nav-right {
    display: none;
  }

  .content {
    width: calc(100vw - 6px);

    left: 3px;
  }
}
