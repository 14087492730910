#about {
  width: 75%;
  height: auto;
  border: 1px solid grey;
  margin: 10px auto;
  text-align: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 150px;
  padding: 40px 15px;
}
#about h1 {
  font-size: 28px;
  margin: 10px auto 20px;
  font-weight: 700;
}
#about p {
  font-size: 20px;
  padding: 10px;
  line-height: 1.6em;
  letter-spacing: 1.5px;
}
#about ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
@media screen and (max-width: 1024px) {
  #about {
    width: 87%;
  }
  #about p {
    font-size: 18px;
  }
}
