@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;300;400;500;600;700&display=swap");

.showcase-container {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  /* border: 2px solid crimson; */
  height: fit-content;
  padding: 10px;
  width: 100%;
  max-width: 1300px;
  margin: 0px auto 100px;
  font-family: "Fira Sans", sans-serif;
}
.showcase-container h1 {
  letter-spacing: 1.5px;
  line-height: 1.4em;
  padding: 5px;
  font-family: "Fira Sans", sans-serif;
  color: skyblue;
}
.showcase-container p {
  letter-spacing: 1.25px;
  line-height: 1.4em;
  padding: 5px;
  color: white;
  font-weight: 700;
}
.showcase-info-section {
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  animation: transition1 1000ms ease-in-out;
}
.showcase-info {
  width: 100%;
  max-width: 500px;
}
.showcase-CTA {
  padding: 5px;
  /* border: 2px solid green; */
  display: flex;
  /* flex-direction: column; */
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
}
.showcase-info-image {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.showcase-info-image img {
  max-width: 280px;
}
.showcase-info-image-lg {
  display: none;
}
#portfolio-btn {
  background-color: var(--color-primary1);
  color: white;
  font-size: 13px;
}
#contact-btn {
  /* background-color: #d7263d; */
  color: white;
  font-size: 13px;
}
.showcase-info-image-lg svg {
  width: 100%;

  /* border: 3px solid red; */
}

#box-1 {
  /* transition: 1s smooth; */
  animation: box1 12s infinite linear;

  opacity: 1;
}

#box-2 {
  animation: box2 12s infinite linear;
}
#box-3 {
  animation: box3 12s infinite linear;
}
#box-4,
#Group,
#js-1,
#node-js-1,
#Vector_21 {
  opacity: 0;
}

#js-1 {
  transform: translateX(0%);
  animation: js-show 12s infinite linear;
}
#react-icon-1 {
  animation: react-show 12s infinite linear;
}
#node-js-1 {
  animation: nodejs-show 12s infinite linear;
}
#mongo-db-1 {
  opacity: 1;
  animation: mongo-show 12s infinite linear;
}
/* #js-1 {
  opacity: 1;
  transform: translateX(-5%);
}
#js-1 {
  opacity: 1;
  transform: translateX(-5%);
} */
/* #hand-2,
#hand-1,
#shirt-1 {
  animation: shake 8s infinite linear;
} */
.btn a:after,
.live-demo a:after {
  background-color: none;
}
#creative-text {
  transition: 800ms ease-in-out;
  animation: transition-a 3.5s;
}

#stack-development {
  transition: 1200ms ease-in-out;
  animation: transition-b 3.5s;
}

#modern-web {
  transition: 1200ms ease-in-out;
  animation: transition-c 3.5s;
}

@media screen and (min-width: 900px) {
  .showcase-container {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    margin: 50px auto 100px;
  }
  .showcase-container p {
    letter-spacing: 1.55px;
    line-height: 1.8em;
    padding: 5px;
  }
  .showcase-info-image {
    display: none;
  }
  .showcase-info-image-lg {
    display: block;
    animation: transition2 1200ms ease-in-out;
  }
  .showcase-info-image-lg img {
    width: 100%;
    max-width: 800px;
  }
  .showcase-CTA {
    flex-direction: row;
  }
  #portfolio-btn {
    background-color: var(--color-primary1);
    color: white;
    font-size: 16px;
  }
  #contact-btn {
    background-color: #d7263d;
    color: white;
    font-size: 16px;
  }
}
@keyframes box1 {
  0% {
    transform: translateY(-5%);

    opacity: 0;
  }
  50% {
    transform: translateY(-5%);
    opacity: 0;
  }
  75% {
    transform: translateY(0%);
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes box2 {
  0% {
    transform: translateY(-10%);

    opacity: 0;
  }
  25% {
    transform: translateY(-10%);
    opacity: 0;
  }
  50% {
    transform: translateY(0%);
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes box3 {
  0% {
    transform: translateY(-20%);

    opacity: 0;
  }
  25% {
    transform: translateY(0%);

    opacity: 1;
  }
  99% {
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    transform: translateY(0%);

    opacity: 0;
  }
}
/* @keyframes shake {
  0% {
    transform: translate(1px, 0.75px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -1px) rotate(-0.5deg);
  }
  20% {
    transform: translate(-1px, 0px) rotate(0.5deg);
  }
  30% {
    transform: translate(0px, 1px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(0.5deg);
  }
  50% {
    transform: translate(-1px, 1px) rotate(-0.5deg);
  }
  60% {
    transform: translate(-1px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(1px, 1px) rotate(-0.5deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(0.5deg);
  }
  90% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -1.5px) rotate(-1deg);
  }
} */

@keyframes js-show {
  0% {
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes react-show {
  0% {
    opacity: 0;
  }
  24% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes nodejs-show {
  0% {
    opacity: 0;
  }

  49% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  74% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes mongo-show {
  0% {
    opacity: 0;
  }

  74% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes transition1 {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes transition2 {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes transition-a {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes transition-b {
  0% {
    transform: translateY(75%);
    opacity: 0;
  }

  50% {
    transform: translateY(75%);

    opacity: 0;
  }
  75% {
    opacity: 1;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes transition-c {
  0% {
    transform: translateY(75%);
    opacity: 0;
  }
  75% {
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
