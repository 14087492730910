.create-post-progress {
  height: 10px;
  background-color: var(--color-primary-social);
  border: none;

  margin-bottom: 10px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  margin: 20px auto;
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid var(--color-primary-social); /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
