.postFeed-post {
  border: 1px solid rgba(128, 128, 128, 0.39);
  display: flex;
  flex-direction: column;
  padding: 25px 20px;
  gap: 15px;

  margin-bottom: 20px;
  width: 100%;
  background: var(--color-light);
  transition: 200ms ease-in-out;
  line-height: 1.6em;
}
.postFeed-post:hover {
  background-color: rgba(128, 128, 128, 0.106);
}
.postFeed-post-info {
  display: flex;
  justify-content: space-around;
}
.postFeed-post-info img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.postFeed-post-info2 {
  display: grid;
  grid-template-columns: 1fr 8fr;
  border-bottom: 2px solid rgba(70, 131, 180, 0.35);
}
.postFeed-post-image2 {
  /* border: 1px solid crimson; */
  display: flex;
  align-items: center;
}
.postFeed-post-image2 img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.postFeed-post-published {
  display: flex;
  justify-content: space-between;
}
.postFeed-post-published-information h3 {
  margin-bottom: -10px;
}
.postFeed-post-published-information h5 {
  padding-left: 10px;
}
.postFeed-post-published-actions {
  display: flex;
  align-items: center;
  padding: 5px;
  color: var(--color-secondary-social);
  cursor: pointer;
}
.postFeed-post-image :hover {
  opacity: 0.96;
}
.postFeed-post-info-stamp {
  display: flex;
  flex-direction: column;
  gap: 0;
  /* border: 3px solid crimson; */
}
.postFeed-post-info-stamp h3,
.postFeed-post-info-stamp h4 {
  color: var(--color-secondary-social);
  font-weight: bold;
}
.postFeed-post-image {
  width: 100%;
  max-width: 800px;
  display: flex;
  cursor: pointer;
}
.postFeed-post-image video {
  width: 100%;
  max-width: 800px;
}
.postFeed-post-stats {
  display: flex;
  justify-content: center;
  /* border: 1px solid red; */
  /* height: 3.5rem; */
  gap: 4rem;
  padding: 0;
}
.postFeed-post-stats > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

.postFeed-post-stats i {
  font-size: 18px;
  cursor: pointer;
}
.postFeed-post-text {
  color: var(--color-secondary-social);
  letter-spacing: 1px;
  cursor: pointer;
  /* border: 1px solid rgba(70, 131, 180, 0.45);
  border-radius: 5px;
  padding: 5px 2px; */
}
.pfp-p {
  margin: 0;
}
.post-feed-action {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}
.post-feed-action > div {
  width: 80px;
  border: none;
  padding: 5px;
  border-radius: 5px;
  font-size: 21px;
  background-color: none;
  border: 1px solid steelblue;
  color: steelblue;
}
.post-feed-action {
  cursor: pointer;
}
.post-feed-share {
  border: 1px solid grey;
}

/*PostFeedComment*/
.post-feed-comment {
  padding: 0px;
}
.post-feed-comment img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.post-feed-comment-buttons {
  margin-top: 10px;
  display: flex;
  justify-content: space-evenly;
}
.post-feed-comment-buttons > button {
  cursor: pointer;
}
.post-feed-comment input {
  width: 100%;
  height: 3rem;
  margin: 5px auto;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 5px;
  padding-top: 0;
}
.post-feed-comment-post {
  display: grid;
  grid-template-columns: 1fr 10fr;
  grid-gap: 10px;
}
.request-submission {
  display: grid;
  grid-template-columns: 1fr 7fr;
  justify-content: center;
  align-content: center;
  width: 80%;
  margin: 0 auto;
}
.request-submission h4 {
  display: flex;
  align-items: center;
  font-weight: 700;
  color: var(--color-secondary-social);
  text-align: center;
}
.animation-check {
  padding: 10px 0;
}

svg {
  width: 30px;
  font-weight: 500;
  display: block;
  margin: 0px auto 0;
}
.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}
.path.circle {
  -webkit-animation: dash 0.9s ease-in-out;
  animation: dash 0.9s ease-in-out;
}
.path.line {
  stroke-dashoffset: 1000;
  -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
  animation: dash 0.9s 0.35s ease-in-out forwards;
}
.path.check {
  stroke-dashoffset: -100;
  -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
  animation: dash-check 0.9s 0.35s ease-in-out forwards;
}
.animation-check p {
  text-align: center;
  margin: 20px 0 60px;
  font-size: 1.25em;
}
.animation-check.success p {
  color: #73af55;
}
.animation-check.error p {
  color: #d06079;
}
.latest-comment-list {
  margin-top: 10px;
  border-top: 2px solid #154c799c;
  padding-top: 5px;
}
.latest-comment-list h4 {
  font-weight: 700;
  color: var(--color-secondary-social);
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
  padding: 3px;
  border-radius: 4px;
}
.latest-comment-list h4:hover {
  background-color: rgba(128, 128, 128, 0.252);
}
.post-feed-latest-comment {
  /* border: 3px solid green; */
  margin-top: 5px;
  display: grid;
  grid-template-columns: 1fr 8fr;
  border-radius: 5px;
  grid-gap: 5px;
}
.post-feed-latest-comment img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
.latest-comment-info {
  padding: 5px;
  border-radius: 5px;
  background-color: rgba(128, 128, 128, 0.555);
}
@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

@media screen and (min-width: 1024px) {
  .post-feed-action > div:hover {
    background-color: var(--color-primary-social);
    color: var(--color-secondary-social);
  }
}
